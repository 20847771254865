// hooks/useGetCountries.js
import { fetchCountries } from "../services/countryService";
import { useSelector } from "react-redux";
import { getCountries as getCountriesFromRedux } from "../selectors/selectors";
import { useDispatch } from "react-redux";
import { setCountries } from "../actions/actions";
import { Country } from "../types";
import useSWR from "swr";

function useGetCountries(): { isLoading: boolean; countries?: Country[] } {
  const dispatch = useDispatch();
  const countriesFromRedux = useSelector(getCountriesFromRedux);

  const { data: countries, isLoading } = useSWR(
    countriesFromRedux ? null : ["countries"], // Don't load when we have countries list from redux
    async () => {
      const result = await fetchCountries();

      dispatch(setCountries(result));

      return result;
    },
  );

  return {
    countries: countriesFromRedux || countries,
    isLoading: isLoading ?? false,
  };
}

export default useGetCountries;
