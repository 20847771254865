import { isAxiosError } from "axios";
import { Country } from "../types";
import axiosInstance from "./axiosIntercept";

export const fetchCountries = async (): Promise<Country[]> => {
  try {
    const response = await axiosInstance.get<Country[]>(
      `${process.env.REACT_APP_API_URL}v1/location/countries`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};
