import { useDispatch } from "react-redux";
import { fetchProductServiceDescription } from "../services/voucherService";
import {
  setSelectedProgramName,
  setSelectedProgramLocation,
  setVouchers,
  setIsLoadingVouchers,
} from "../actions/actions";
import axiosInstance from "../services/axiosIntercept";
import { isAxiosError } from "axios";
import { Program } from "../types";

const useGetVouchers = (uuid?: string) => {
  const dispatch = useDispatch();

  const getVouchers = async (id: number) => {
    dispatch(setIsLoadingVouchers(true));
    dispatch(setVouchers([]));

    console.log("While fetching vouchers data UUID:", uuid);

    try {
      const programResponse = await axiosInstance.get<Program[]>(
        `${process.env.REACT_APP_API_URL}v1/voucher/portal/account/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );

      console.log("in getVouchers Hook");

      const filtered_data = await programResponse.data.filter((data) => {
        if (data.id === id) {
          return data;
        } else {
          return null;
        }
      });

      console.log("in getVouchers Hook - data", filtered_data);

      //Sandeep Nov 20, 2023 3pm AST added If condition
      if (filtered_data.length <= 0) {
        console.log("empty");
        // dispatch({ type: "SET_SELECTED_LP", payload: [] });
        dispatch({ type: "SET_VOUCHERS", payload: [] });
        dispatch({ type: "SET_SELECTED_COLOR", payload: [] });
        dispatch({ type: "SET_BUSINESS_IMAGE_NAME", payload: [] });
      } else {
        //dispatch({ type: "SET_SELECTED_LP", payload: filtered_data[0] });
        const vouchers =
          filtered_data[0]?.vouchers &&
          (await Promise.all(
            filtered_data[0]?.vouchers.map(async (voucher) => {
              if (voucher.productServiceRefId) {
                const productServiceDescription: string =
                  await fetchProductServiceDescription(
                    voucher.productServiceRefId,
                  );
                voucher.productServiceDescription = productServiceDescription
                  ? productServiceDescription
                  : "";
              }

              return voucher;
            }),
          ));

        dispatch({ type: "SET_VOUCHERS", payload: vouchers });
        dispatch({
          type: "SET_SELECTED_COLOR",
          payload: filtered_data[0]?.brandColor,
        });
        dispatch({
          type: "SET_BUSINESS_IMAGE_NAME",
          payload: filtered_data[0]?.imageURL,
        });
        if (filtered_data[0]) {
          dispatch(setSelectedProgramName(filtered_data[0].name));
          dispatch(
            setSelectedProgramLocation(filtered_data[0].physicalAddress),
          );
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        console.error(
          "API Error:",
          error.response?.status,
          error.response?.data,
        );
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    } finally {
      dispatch(setIsLoadingVouchers(false));
    }
  };

  return getVouchers;
};

export default useGetVouchers;
