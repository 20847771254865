import React, { useState, useEffect, useMemo } from "react";
import { Modal, Table, Select, Input, notification } from "antd";
import AppButton from "../AppButton/app_button";
import useGetLocations from "../../Hooks/getLocation";
import { ProgramLocation } from "../../types";
import useUpdateLocation from "../../Hooks/updateLocation";
import useGetCountries from "../../Hooks/getCountries";

interface CurrencySettingsProps {
  visible: boolean;
  onClose: () => void;
  programUuid: string;
}

export interface CurrencySettingData {
  key: string;
  enable: boolean;
  locationLabel: string;
  locationId: string;
  country: string;
  currency: string;
  amount: string;
}

interface CountryResponse {
  id: number;
  name: string;
  currency: string;
  currency_name: string;
  currency_symbol: string;
  currencies?: Array<{ code: string; name: string }>;
}

interface CountryWithCurrencies {
  id: string;
  name: string;
  currencies: Array<{ code: string; name: string }>;
}

export const CurrencySettings: React.FC<CurrencySettingsProps> = ({
  visible,
  onClose,
  programUuid,
}) => {
  const [tableData, setTableData] = useState<CurrencySettingData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log(programUuid);
  const getLocations = useGetLocations(programUuid);
  const updateLocation = useUpdateLocation();
  const [errorMessages, setErrorMessages] = useState<
    Record<string, Partial<Record<keyof CurrencySettingData, string>>>
  >({});

  const { countries, isLoading: isLoadingCountries } = useGetCountries();

  const uniqueCurrencies = useMemo<
    Record<string, { code: string; name: string }>
  >(() => {
    if (!countries) {
      return {};
    }

    return countries.reduce<Record<string, { code: string; name: string }>>(
      (accum, country) => {
        if (!accum[country.currency]) {
          accum[country.currency] = {
            code: country.currency,
            name: `${country.currency} (${country.currency_symbol})`,
          };
        }
        return accum;
      },
      {},
    );
  }, [countries]);

  const countriesWithCurrency = useMemo<CountryWithCurrencies[]>(
    () =>
      countries?.map((country: CountryResponse) => ({
        id: country.id.toString(),
        name: country.name,
        currencies: Array.from(Object.values(uniqueCurrencies)),
      })) || [],
    [countries, uniqueCurrencies],
  );

  // Fetch locations and countries when modal opens
  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const locations = await getLocations();

          setTableData(
            locations.map((loc: ProgramLocation) => ({
              key: String(loc.id),
              enable: Boolean(loc.country),
              locationLabel: loc.locationName,
              locationId: String(loc.id),
              country: loc.country || "",
              currency: loc.currencyCode || "",
              amount: String(loc.memberReceiptStampAmount || 0),
            })),
          );
        } catch (error) {
          console.error("Failed to fetch data:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [visible, programUuid, getLocations]);

  const currencyColumns = [
    // {
    //   title: "Enable",
    //   dataIndex: "enable",
    //   key: "enable",
    //   width: 80,
    //   render: (_: any, record: CurrencySettingData) => (
    //     <Switch
    //       size="small"
    //       checked={record.enable}
    //       onChange={(checked) => handleEnableChange(record.key, checked)}
    //       style={{
    //         background: record.enable ? "var(--orange)" : undefined,
    //         borderColor: record.enable ? "var(--orange)" : undefined,
    //       }}
    //     />
    //   ),
    // },
    {
      title: "Location Label",
      dataIndex: "locationLabel",
      key: "locationLabel",
      width: 200,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 200,
      render: (_: any, record: CurrencySettingData) => (
        <div>
          <Select
            // disabled={!record.enable}
            value={record.country}
            onChange={(value) => handleCountryChange(record.key, value)}
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            allowClear
            showSearch
          >
            {countriesWithCurrency.map((country) => (
              <Select.Option key={country.id} value={country.name}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
          {errorMessages[record.key]?.country && (
            <div style={{ color: "red", paddingTop: "5px" }}>
              {errorMessages[record.key]?.country}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: 200,
      render: (_: any, record: CurrencySettingData) => {
        return (
          <Select
            // disabled={!record.enable || !record.country}
            value={record.currency}
            onChange={(value) => handleCurrencyChange(record.key, value)}
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            allowClear
            showSearch
          >
            {countriesWithCurrency[0]?.currencies.map((currency) => (
              <Select.Option key={currency.code} value={currency.code}>
                {currency.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Amount Spent for 1 Stamp",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (_: any, record: CurrencySettingData) => (
        <div>
          <Input
            // disabled={!record.enable}
            value={record.amount}
            onChange={(e) => handleAmountChange(record.key, e.target.value)}
            placeholder="000.00"
            maxLength={8}
            pattern="[0-9.]*"
          />
          {errorMessages[record.key]?.amount && (
            <div style={{ color: "red", paddingTop: "5px" }}>
              {errorMessages[record.key]?.amount}
            </div>
          )}
        </div>
      ),
    },
  ];

  // const handleEnableChange = (key: string, checked: boolean) => {
  //   setTableData((prev) =>
  //     prev.map((row) => (row.key === key ? { ...row, enable: checked } : row)),
  //   );
  // };

  const handleCountryChange = (key: string, value: string) => {
    // Find the selected country and its currency
    const selectedCountry = countries?.find((c) => c.name === value);
    const defaultCurrency = selectedCountry?.currency || "";

    setTableData((prev) =>
      prev.map((row) =>
        row.key === key
          ? { ...row, country: value, currency: defaultCurrency }
          : row,
      ),
    );
  };

  const handleCurrencyChange = (key: string, value: string) => {
    setTableData((prev) =>
      prev.map((row) => (row.key === key ? { ...row, currency: value } : row)),
    );
  };

  const handleAmountChange = (key: string, value: string) => {
    let newValue = value.replace(/[^0-9.]/g, "");
    if (newValue.split(".").length > 2) {
      newValue = newValue.slice(0, -1);
    }
    setTableData((prev) =>
      prev.map((row) => (row.key === key ? { ...row, amount: newValue } : row)),
    );
  };

  const handleSave = async () => {
    const errors = tableData.reduce<typeof errorMessages>((accum, row) => {
      const error: (typeof errorMessages)[string] = {};
      if (!row.country) {
        error.country = "Please select a country.";
      }
      if (!parseFloat(row.amount)) {
        error.amount = "Amount must be a number greater than zero.";
      }

      if (Object.values(error).length) {
        accum[row.key] = error;
      }

      return accum;
    }, {});

    setErrorMessages(errors);

    if (Object.values(errors).length) {
      return;
    }

    try {
      await Promise.all(
        tableData.map(async (row) => {
          // Get the original location data
          const locations = await getLocations();
          const currentLocation = locations.find(
            (loc) => String(loc.id) === String(row.locationId),
          );

          if (!currentLocation) {
            console.warn(
              `Location ${row.locationId} not found, skipping update`,
            );
            return; // Skip this location if not found
          }

          const updateBody = {
            businessName: currentLocation.businessName,
            locationName: currentLocation.locationName,
            address: currentLocation.address,
            logoImageId: currentLocation.logoImageId,
            country: row.country,
            currencyCode: row.currency,
            memberReceiptStampAmount: row.amount ? Number(row.amount) : 0,
          };
          await updateLocation(row.locationId, updateBody);
        }),
      );

      notification.success({
        message: "Settings saved successfully",
      });
      onClose();
    } catch (error) {
      console.error("Save error:", error);
      notification.error({
        message: "Failed to save settings",
        description: "Please try again",
      });
    }
  };

  return (
    <Modal
      title="Receipt Scanning"
      open={visible}
      onCancel={onClose}
      footer={[
        <AppButton key="submit" type="primary" onClick={handleSave}>
          Save
        </AppButton>,
      ]}
      width={900}
    >
      <Table
        loading={isLoading || isLoadingCountries}
        columns={currencyColumns}
        dataSource={tableData}
        pagination={false}
        rowKey="key"
      />
    </Modal>
  );
};
